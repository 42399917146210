import React, {useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import TextComponent from '../formikComponents/TextComponent';
import CheckboxComponent from '../formikComponents/CheckboxComponent';
import {CP_API_URL, API_URL, isDev, nonUndefined} from '../../common/util';
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import s from './contacto.module.scss';
import Swal from "sweetalert2";

const ContactoSchema = Yup.object().shape({
    nombre: Yup.string()
        .trim()
        .max(255)
        .required('Nombre requerido.'),
    cp: Yup.string()
        .trim()
        .length(5, 'El código postal tiene que contener 5 caracteres')
        .required('El código postal es requerido'),
    apellidos: Yup.string()
        .trim()
        .max(255)
        .required('Apellidos requerido.'),
    email: Yup.string()
        .trim()
        .max(255)
        .email('Debe introducir un email válido')
        .required('Email requerido.'),
    telefono: Yup.string()
        .trim()
        .max(15)
        .required('Teléfono requerido.'),
    edad: Yup.string()
        .trim()
        .max(2, 'La edad no puede ser más de dos caracteres')
        .required('Edad requerida.'),
    localidad: Yup.string()
        .trim()
        .max(255)
        .required('Localidad requerida.'),
    rgpd: Yup.boolean()
        .oneOf([true], "Debe aceptar los términos y condiciones para continuar"),

})

const makeid = () => {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 22; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

const ContactoStep2 = ({onNextStep, setContactData}) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [localidad, setLocalidad] = useState('');
    const [localidadDescription, setLocalidadDescription] = useState('');
    const [provincia, setProvincia] = useState(false);
    const [provinciaDescripcion, setProvinciaDescripcion] = useState('')
    const [localidades, setLocalidades] = useState([]);

    const contactoValues = {
        nombre: isDev ? 'Jesus' : '',
        apellidos: isDev ? 'Yepes' : '',
        email: isDev ? 'jesus@orquestamedia.com' : '',
        telefono: isDev ? '123123132' : '',
        edad: isDev ? '11' : '',
        localidad: '',
        cp: '',
        rgpd: isDev,
        form_name: makeid()
    }

    const rgpd = () => {
        Swal.fire({
            showClass: {
                popup: '',
                icon: ''
            },
            hideClass: {
                popup: '',
            },
            confirmButtonColor: '#DEA463',
            confirmButtonText: 'Cerrar',
            title: 'Información de Privacidad',
            html: '<p>Queremos informarle que el Responsable del Tratamiento de los datos personales que nos facilitas es Asistencia Clínica Universitaria de Navarra S.A. de Seguros y Reaseguros. (en adelante ACUNSA). NIF A- 50005586. Avda. Pío XII, 57, 31008 Pamplona – Navarra. Teléfono:  <a href="tel:948 19 46 00">948 19 46 00</a>.</p><p>En ACUNSA tratamos estos datos personales con la finalidad de enviarte, a través de cualquier medio, la información solicitada sobre nuestros productos o servicios. Estos datos se conservarán mientras no solicites el cese de la actividad y no se cederán a terceros salvo en los casos en que exista una obligación legal o lo consientas expresamente.</p><p>Tiene derecho a acceder, rectificar y portar los datos, limitar u oponerse al tratamiento, así como otros derechos, como se explica en la información adicional, que podrá ejercer en nuestra página web en el formulario creado al efecto “Ejercicio de Derechos ArSol”: <a href='http://www.acunsa.es/ejercicio-de-derechos-arsol/'>www.acunsa.es/ejercicio-de-derechos-arsol/</a> </p><p>Puedes encontrar más información sobre tu privacidad en <a href="https://www.acunsa.es/rgpd" target="_blank" rel="noopener noreferrer">https://www.acunsa.es/rgpd/</a>.</p>'
        })
    }

    const loadCP = (value, cb) => {

        if (value.length < 2) return cb([])

        return fetch(CP_API_URL + 'cps/?term=' + value)
            .then(r => r.json())
            .then(d => {

                if (!d)
                    return cb([]);

                const data = d.data.map(item => ({
                    value: item,
                    label: item
                }));
                return cb(data)
            })
            .catch(e => console.log(e))
    }

    const loadLocalidad = (cp) => {

        if (cp.length < 5) return


        return fetch(CP_API_URL + 'cp-detail/?term=' + cp)
            .then(r => r.json())
            .then(d => {

                if (!d.listaLocalidades && !d.provincia)
                    return;

                const data = d.listaLocalidades.map(item => ({
                    value: item.codigo,
                    label: item.descripcion
                }));


                setLocalidad('')
                setLocalidadDescription('')
                setLocalidades(data)
                setProvincia(d.provincia.codigo)
                setProvinciaDescripcion(d.provincia.descripcion)


            })
            .catch(e => console.log(e))
    }

    const saveFormFormulariosAcunsa = (values, submitted = false) => {
        console.log('Guardamos')
        console.log(values)
        values.timestamp = Math.floor(Date.now());
        values.form_real_url = 'https://acunsafirst.es/contacto';
        values.nombre_apellidos = 'AcunsaFirst';

        if (submitted){
          values.form_enviado = 'true';
        }

        const url = isDev ? 'http://formularios.acunsa.loc/index.php?r=site/save&json=true' : 'https://formularios.acunsa.es/index.php?r=site/save&json=true';

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(values)
        }).then(e => console.log('datos guardados')).catch(e => console.error(e))


    }

    return <div className={`container ${s.contact}`}>
        <Formik
            initialValues={contactoValues}
            isInitialValid={false}
            onSubmit={(values, {resetForm}) => {
                setIsSubmitting(true);

                values['provincia'] = provincia;
                values['provinciaDescripcion'] = provinciaDescripcion;
                values['localidadDescription'] = localidadDescription;
                saveFormFormulariosAcunsa(values, true);
                fetch(API_URL, {
                        method: 'POST',
                        body: JSON.stringify(values)
                    },
                )
                    .then(d => d.json())
                    .then(d => {
                        console.log(d)
                        if (d.status) {
                            setTimeout(() => {
                                onNextStep()
                            }, 200);
                            setContactData(values);
                            setIsSubmitting(false);
                            resetForm();
                        } else {
                            alert(d.msg);
                        }
                    })

            }}
            validationSchema={ContactoSchema}
        >
            {({isValid, errors, touched, values, setFieldValue}) => (
                <div className={s.contactPage + ' contacto'}>
                    <Form className={s.contactForm} onBlur={() => {
                        if (values.rgpd === true) {
                            saveFormFormulariosAcunsa(values)
                        }
                    }}>
                        <div className={s.headerForm}>
                            <p>Cumplimente el formulario y nos pondremos en contacto con usted para informarle
                                personalmente de las ventajas de la póliza.</p>
                        </div>
                        <Field name="nombre" placeholder="Nombre" component={TextComponent} type="text"
                               error={errors.nombre !== undefined && touched.nombre}/>

                        <Field name="apellidos" placeholder="Apellidos" component={TextComponent} type="text"
                               error={errors.apellidos !== undefined && touched.apellidos}/>

                        <Field name="email" placeholder="Email" component={TextComponent} type="text"
                               error={errors.email !== undefined && touched.email}/>

                        <Field name="telefono" placeholder="Teléfono" component={TextComponent} type="text"
                               error={errors.telefono !== undefined && touched.telefono}/>

                        <Field name="edad" placeholder="Edad" component={TextComponent} type="number"
                               error={errors.edad !== undefined && touched.edad}/>

                        <div className="form-group from-group-acn_select2">
                            <AsyncSelect
                                noOptionsMessage={() => 'Intoduzca Código Postal (mínimo 2 caracteres)'}
                                className="acn_select2"
                                loadingMessage={() => 'Cargando'}
                                cacheOptions
                                placeholder={values.cp.length > 0 ? values.cp : "Introduzca Código Postal"}
                                defaultOptions={false}
                                loadOptions={loadCP}
                                value={values.cp}
                                onChange={
                                    option => {
                                        setFieldValue('cp', option.value)
                                        loadLocalidad(option.value)
                                    }
                                }
                            />
                            <ErrorMessage name="cp" className="invalid-feedback" component="div"/>
                        </div>

                        <div className="form-group from-group-acn_select2">
                            <Select
                                className="acn_select2"
                                value={localidad}
                                options={localidades}
                                placeholder={localidades.length ? 'Seleccione una localidad' : 'Seleccione Código Postal'}
                                noOptionsMessage={() => <>{values.cp.length > 0 ? `No hay localidades para ${values.cp}` : 'Introduzca CP primero'}</>}
                                onChange={option => {
                                    setLocalidad(option)
                                    setFieldValue('localidad', option.value)
                                    setLocalidadDescription(option.label)
                                }}
                            />

                            <ErrorMessage name="localidad" className="invalid-feedback" component="div"/>
                        </div>


                        <div className={s.footerForm}>
                            <Field name="rgpd" label="He leído y acepto las" component={CheckboxComponent}/> <a href='#'
                                                                                                                className={s.linkPrimary}
                                                                                                                onClick={rgpd}>condiciones
                            legales</a>

                            <div className={s.buttons}>
                                <button type="submit" disabled={isSubmitting || !isValid} className={s.submitButton}>
                                    {isSubmitting ? 'Cargando...' : 'Enviar'}
                                </button>
                            </div>
                        </div>

                    </Form>
                    <div className={s.contactDetails}>
                        <div className={s.contactDetailsChild}>
                            <p className={s.itemValue}>
                                {nonUndefined(values.nombre)} {nonUndefined(values.nombre).length > 0 && <br/>}
                                {nonUndefined(values.apellidos)} {nonUndefined(values.apellidos).length > 0 && <br/>}
                                {nonUndefined(values.email)} {nonUndefined(values.email).length > 0 && <br/>}
                                {nonUndefined(values.telefono)} {nonUndefined(values.telefono).length > 0 && <br/>}
                                {nonUndefined(values.edad)} {nonUndefined(values.edad.toString()).length > 0 && <br/>}
                                {nonUndefined(values.cp)} {nonUndefined(values.cp).length > 0 && <br/>}
                                {localidad?.label && localidad.label}{localidad?.label && <br/>}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    </div>
}

export default ContactoStep2
